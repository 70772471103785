import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'
import closeIcon from '../../assets/svg/icon_close.svg'

import './styles.scss'
import { FC, useEffect, useState } from 'react'
import { VideoTypes } from '../../components/ExampleItem'

interface VideoPresentationProps {
  video: VideoTypes
  onClose: () => void
}

export enum Orientations {
  WEB = 'web',
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

const MAX_WEB_WINDOW_WIDTH = 1200

function getOrientation(): Orientations {
  if (window.innerWidth > MAX_WEB_WINDOW_WIDTH) {
    return Orientations.WEB
  }

  if (window.matchMedia('(orientation: landscape)').matches) {
    return Orientations.LANDSCAPE
  }

  return Orientations.PORTRAIT
}

export const VideoPresentation: FC<VideoPresentationProps> = ({
  video,
  onClose,
}) => {
  const [orientation, setOrientation] = useState<Orientations>(getOrientation())

  useEffect(() => {
    const onWindowSizeChange = () => {
      setOrientation(getOrientation())
    }

    window.addEventListener('resize', onWindowSizeChange)

    return () => {
      window.removeEventListener('resize', onWindowSizeChange)
    }
  }, [])

  return (
    <div
      className="video-presentation"
      onClick={(e) => {
        e.stopPropagation()

        if ((e.target as Element).localName === 'div') {
          onClose()
        }
      }}
    >
      <div className="video-presentation--video-popup">
        <span onClick={onClose}>
          <img src={closeIcon} alt="" />
        </span>
        {orientation === Orientations.WEB && (
          <video controls src={video[Orientations.WEB]} autoPlay>
            <track kind="captions" />
          </video>
        )}
        {orientation === Orientations.PORTRAIT && (
          <video controls src={video[Orientations.PORTRAIT]} autoPlay>
            <track kind="captions" />
          </video>
        )}
        {orientation === Orientations.LANDSCAPE && (
          <video controls src={video[Orientations.LANDSCAPE]} autoPlay>
            <track kind="captions" />
          </video>
        )}

        {/* <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/aGSYKFb_zxg"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        /> */}
      </div>
    </div>
  )
}
